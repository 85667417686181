<template>
    <div class="rules">

        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Rules</div>
        </div>

        <div class="main">
            <div class="l" v-if="loading">
                <loader/>
            </div>
            <div class="rule" v-for="(rule, i) in rules" :key="i">
                <div class="rule_title">{{ rule.title }}</div>
                <div class="text">{{ rule.text }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';

import loader from '../../components/loader.vue'

export default {
    components: {
        back, loader
    },
    data() {
        return {
            loading: true,
            rules: []
        }
    },
    mounted() {
        this.$http.post('/rules')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.rules = r.rules
                this.loading = false
            }
        })
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>
    .rules {
        background-color: white;
    }
    .head {
        display: grid;
        grid-template-columns: calc(50% - 22px) 50%;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .title {
        font-weight: 500;
    }

    .main {
        height: calc(100vh - 51px);
        /* padding: 15px; */
        box-sizing: border-box;
        overflow: auto;
        background-color: #edeef0;
    }
    .l {
        padding: 50px 0px;
    }

    .rule {
        margin-top: 10px;
        padding: 15px;
        background-color: white;
    }
    .rule_title {
        font-weight: 500;
    }
    .text {
        margin-top: 15px;
        font-size: 15px;
        line-height: 22px;
        color: gray;
    }
</style>